import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbAnchor,
} from '../components/breadcrumb';
import serializeHyperlink from '../utils/serializeHyperlink';

type TermsAndConditionsPagePros = {
  pageContext: {
    language: string;
  };
  data: any;
};

const TermsAndConditions = ({
  pageContext,
  data,
}: TermsAndConditionsPagePros) => {
  const { language } = pageContext;
  const { formatMessage } = useIntl();
  const TermsAndConditionsQuery = data.allPrismicSimplePage.nodes[0];
  const { data: pageData } = TermsAndConditionsQuery;

  return (
    <Layout lang={language}>
      <SEO
        title={formatMessage({
          id: 'termsAndConditions',
          defaultMessage: 'Terms and conditions',
        })}
      />
      <Breadcrumb className="breadcrumb--workbench">
        <BreadcrumbAnchor
          label={formatMessage({
            id: 'home',
            defaultMessage: 'home',
          })}
          url="/"
        />
        <BreadcrumbDivider />
        <BreadcrumbAnchor
          active
          label={formatMessage({
            id: 'termsAndConditions',
            defaultMessage: 'Terms and conditions',
          })}
        />
      </Breadcrumb>
      <Section>
        <RichText
          render={pageData.text.richText}
          serializeHyperlink={serializeHyperlink}
        />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query TermsAndConditionsQuery($prismicLang: String) {
    allPrismicSimplePage(
      filter: {
        lang: { eq: $prismicLang }
        tags: { eq: "terms-and-conditions" }
      }
    ) {
      nodes {
        _previewable
        data {
          text {
            richText
          }
        }
        last_publication_date
      }
    }
  }
`;

/* eslint-disable-next-line */
// @ts-ignore eslint-disable-line
export default withPrismicPreview(TermsAndConditions);
